<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <!-- 搜索 -->
    <el-form ref="form" inline :model="searchData" label-width="">
      <!-- <el-form-item label="类型">
        <el-select style="width: 200px" v-model="searchData.type" placeholder="请选择" size="mini">
          <el-option label="活动反馈" :value="0"> </el-option>
          <el-option label="活动举报" :value="1"> </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="活动">
        <SelectActivity width="200px" v-model="searchData.activityId"></SelectActivity>
      </el-form-item>
      <el-form-item label="用户姓名">
        <SelectCustomer width="200px" v-model="searchData.uid"></SelectCustomer>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
        <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>

    <!-- list -->
    <el-table :data="list">
      <!-- 拓展 -->
      <el-table-column type="expand">
        <template slot-scope="{ row }">
          <el-form label-position="left">
            <el-form-item label="活动详情">
              <div class="table-content-expand">
                <div class="item">
                  <span>活动名称：</span>
                  <span>{{ row.activityInfo.name }}</span>
                </div>

                <div class="item">
                  <span>活动开始时间：</span>
                  <span>{{ row.activityInfo.startTime }}</span>
                </div>
                <div class="item">
                  <span>活动结束时间：</span>
                  <span>{{ row.activityInfo.endTime }}</span>
                </div>
                <div class="item">
                  <span>活动状态：</span>
                  <span>{{ row.activityInfo.statusName }}</span>
                </div>
                <div class="item">
                  <span>活动地址：</span>
                  <span>{{ row.activityInfo.address }}</span>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="反馈用户信息" min-width="160">
        <template slot-scope="{ row }">
          <div class="table-content">
            <div class="item">
              <span>姓名：</span>
              <span>{{ row.realname }}</span>
            </div>
            <div class="item">
              <span>手机号：</span>
              <span> {{ row.mobile }}</span>
            </div>
            <div class="item">
              <span> 头像：</span>
              <span>
                <el-image
                  style="width: 50px; height: 50px; border-radius: 5px"
                  :preview-src-list="[row.face]"
                  :src="row.face"
                  fit="contain"
                ></el-image>
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="activityName" label="活动名称" min-width="120" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="typeName" label="类型" min-width="120">
        <template slot-scope="{ row }">
          <el-tag :type="row.type == 0 ? 'primary' : 'warning'" size="mini">{{ row.typeName }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" min-width="120">
        <template slot-scope="{ row }">
          <el-tag v-if="row.status == 0" type="warning" size="mini">待审核</el-tag>
          <el-tag v-if="row.status == 1" type="primary" size="mini">审核通过</el-tag>
          <el-tag v-if="row.status == 2" type="danger" size="mini">审核拒绝</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="content" label="反馈内容" min-width="160" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="replyContent" label="回复内容" min-width="140" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="createTime" label="创建时间" min-width="160" show-overflow-tooltip> </el-table-column>
      <el-table-column label="操作" width="150" fixed="right">
        <template slot-scope="{ row }">
          <!-- <el-link v-if="row.status == 0" style="margin-right: 10px" type="primary" :underline="false" @click="handleReview(row)">审核</el-link> -->
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleDetail(row)">详情</el-link>
          <el-link type="primary" :underline="false" @click="handleDel(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pagesize" @pagination="getList" />
    <!-- 审核弹窗 -->
    <el-dialog title="审核" :visible.sync="show_review" width="700px">
      <el-form ref="form" :model="reviewData" label-width="80px">
        <el-form-item label="审核状态" prop="status" :rules="rules">
          <el-radio-group v-model="reviewData.status">
            <el-radio :label="0">待审核</el-radio>
            <el-radio :label="1">审核通过</el-radio>
            <el-radio :label="2">审核拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="回复内容" prop="replyContent" :rules="rules">
          <el-input style="width: 350px" type="textarea" v-model="reviewData.replyContent" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="show_review = false" >取 消</el-button>
        <el-button size="small" type="primary" @click="submitReview">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 详情弹窗 -->
    <el-drawer size="45%" :visible.sync="show_detail" direction="rtl">
      <Detail :detail="detail"></Detail>
    </el-drawer>
  </div>
</template>

<script>
import { getListAPI, delAPI, reviewAPI, getDetailAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
import SelectActivity from '@/views/activity/activity-list/select-activity-list.vue'
import SelectCustomer from '@/views/customer/customer-list/select-customer-list.vue'
import Detail from './detail.vue'
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  components: { SelectActivity, SelectCustomer, Detail },
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      show_review: false,
      show_detail: false,
      list: [],
      reviewData: {
        id: '',
        status: '',
        replyContent: ''
      },
      searchData: {
        page: 1,
        pagesize: 10,
        type: 0,
        activityId: '',
        uid: ''
      },
      total: 0,
      detail: {}
    }
  },

  watch: {},
  activated() {
    this.getList()
  },
  mounted() {},

  methods: {
    // 列表
    async getList() {
      const res = await getListAPI(this.searchData)
      this.total = res.totalRow
      this.list = res.records
    },

    // 删除
    handleDel({ id }) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.getList()
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 审核按钮
    handleReview({ id }) {
      this.reviewData.id = id
      this.show_review = true
    },
    // 提交审核
    submitReview() {
      reviewAPI(this.reviewData).then(() => {
        this.$message.success('提交成功')
        this.getList()
        this.show_review = false
      })
    },
    // 详情按钮
    async handleDetail({ id }) {
      this.detail = await getDetailAPI(id)
      this.show_detail = true
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .table-content {
    .item {
      display: flex;
      align-items: center;
      span {
        &:first-child {
          width: 70px;
          text-align: right;
          padding-right: 10px;
        }
      }
    }
  }
  .table-content-expand {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    .item {
      display: flex;
      align-items: center;
      span {
        &:first-child {
          width: 120px;
          text-align: right;
          padding-right: 10px;
        }
      }
    }
  }
  .el-drawer {
    .el-drawer__header {
      padding: 0;
    }
  }
}
</style>
