import { http } from '@/http/axios.js'

// 列表
export function getListAPI(params) {
  return http({
    url: `/manage/user/page`,
    method: 'get',
    params
  })
}
// bill
export function getBillListAPI(params) {
  return http({
    url: `/manage/user/balance/record/page`,
    method: 'get',
    params
  })
}

export function getDetailAPI(id) {
  return http({
    url: `/manage/user/detail`,
    method: 'get',
    params: { id }
  })
}
//举报列表
export function getJubaoListAPI(params) {
  return http({
    url: `/manage/user/pageReport`,
    method: 'get',
    params
  })
}
//充值
export function chargeAPI(data) {
  return http({
    url: `/manage/user/chargeMoney`,
    method: 'post',
    data
  })
}
// 邀请人
export function getInviterListAPI(params) {
  return http({
    url: `/manage/user/page`,
    method: 'get',
    params
  })
}

